import React from 'react';
import SEO from './seo';

export const PageMeta = ({ metaImage, metaDescription, metaTitle, metaKeywords, robots }) => {
  return (
    <SEO
      title={metaTitle}
      description={metaDescription}
      keywords={metaKeywords}
      metaRobots={robots}
      image={metaImage}
    />
  );
};
